import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import UserSettingsModel from "./models/userSettings";

@Injectable({
  providedIn: "root",
})
export class UserSettings {
  public static settings: UserSettingsModel = {
    DateFormatterValues: {
      date: "yyyy-MM-dd",
      time: "HH:mm",
      dateTime: "yyyy-MM-dd HH:mm",
    },
  };

  public constructor(public http: HttpClient) {}

  public getUserSettings() {
    // you can use this method to overwrite the default user settings once a user has logged in
  }
}
