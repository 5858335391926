import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "dateTime",
})
/**
 * TODO: This pipe should fetch the datetime preference from the user instead
 */
export class DateTimePipe extends DatePipe implements PipeTransform {
  public format = "yyyy-MM-dd HH:mm";
  public NA = "n.a.";

  public transform(value: any): any {
    if (!value) {
      return this.NA;
    }
    const returnValue = value instanceof Date ? value : new Date(value);
    if (returnValue.toString().toLowerCase() === "invalid date") {
      return this.NA;
    }

    return super.transform(returnValue, this.format);
  }
}
