import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-us",
  templateUrl: "./us.component.html",
  styleUrls: ["./us.component.css"],
})
export class UsComponent implements OnInit {
  public constructor() {}

  public ngOnInit() {}
}
