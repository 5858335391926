import { StateContainerComponent } from "app/infrastructure/state/state-container-component/state-container.component";
import { OnInit, Component } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { User } from "./models/user";
import { UserState } from "./state/user.state";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { GetCurrentUser } from "./state/actions/GetCurrentUser";
import { UpdateUser } from "./state/actions/UpdateUser";
import { DeleteUser } from "./state/actions/DeleteUser";
import { MatDialog } from "@angular/material/dialog";
import { DeleteConfirmationDialogComponent } from "./DeleteConfirmationDialog.component";
import { JoinOrganization } from "../organizations/state/actions/JoinOrganization";
import { JoinDialogComponent } from "./JoinDialog.component";
import { JoinModel } from "./state/models/JoinModel";

@Component({
  templateUrl: "UserDetails.component.html",
  styleUrls: ["UserDetails.component.scss"],
})
export class UserDetailsComponent extends StateContainerComponent implements OnInit {
  @Select(UserState.CurrentUser)
  public $stateUser: Observable<User>;
  public currentUser: User;
  public formUser: User;
  public inviteKey: string;
  public organizationId: string;

  public constructor(
    private store: Store,
    private router: Router,
    private translate: TranslateService,
    private dialog: MatDialog,
    private joinDialog: MatDialog
  ) {
    super();
  }

  public ngOnInit() {
    super.ngOnInit();
    this.refresh();
  }
  public refresh() {
    this.store.dispatch(new GetCurrentUser());
  }

  public submit() {
    this.store.dispatch(new UpdateUser(this.formUser));
  }

  public register() {
    this.router.navigate(["users/register"]);
  }
  public delete() {
    this.store.dispatch(new DeleteUser(this.formUser.id));
    this.store.dispatch(new GetCurrentUser());
  }

  public deleteDialog() {
    this.dialog.open(DeleteConfirmationDialogComponent, {
      width: "250px",
      data: { id: this.formUser.id },
    });
  }

  public joinOrganization() {
    const dialogRef = this.joinDialog.open(JoinDialogComponent, {
      width: "500px",
      data: { inviteKey: this.inviteKey, organizationId: this.organizationId },
    });

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result: JoinModel) => {
        if (result) {
          this.store.dispatch(new JoinOrganization(result.organizationId, result.inviteKey));
        }
      })
    );
  }

  public mapStateToProps(): void {
    this.bindSelectorToProperty(this.$stateUser, "currentUser", (val: User) => {
      this.formUser = { ...val };
      return val;
    });
  }
}
