import { StateContainerComponent } from "app/infrastructure/state/state-container-component/state-container.component";
import { OnInit, Component } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { User } from "../users/models/user";
import { UserState } from "../users/state/user.state";
import { MatDialog } from "@angular/material/dialog";
import { InviteDialogComponent } from "./inviteDialog.component";
import { InviteToOrganization } from "./state/actions/InviteToOrganization";
import { InviteModel } from "./state/models/InviteModel";
import { CreateOrganization } from "./state/actions/CreateOrganization";
import { CreateOrganizationDialogComponent } from "./CreateOrganizationDialog.component";
import { GetUsersForYourOrganization } from "./state/actions/GetUsersForYourOrganization";
import { OrganizationState } from "./state/organization.state";
import { APPROUTES } from "app/app.routes.strings";

@Component({
  templateUrl: "organization.component.html",
})
export class OrganizationComponent extends StateContainerComponent implements OnInit {
  @Select(UserState.CurrentUser)
  public $currentUser: Observable<User>;
  public currentUser: User;
  @Select(OrganizationState.Users)
  public $users: Observable<User[]>;
  public users: User[];

  public columnsToDisplay = ["username", "email"];
  public inviteEmails: string[] = [];
  public inviteModel: InviteModel;
  public organizationName = "";

  public constructor(
    private store: Store,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog,
    public createDialog: MatDialog
  ) {
    super();
  }
  public ngOnInit() {
    super.ngOnInit();
    this.getUsersForYourOrganization();
  }

  public getUsersForYourOrganization() {
    this.store.dispatch(new GetUsersForYourOrganization(this.currentUser?.organizationId));
  }

  public createOrganization() {
    const dialogRef = this.createDialog.open(CreateOrganizationDialogComponent, { data: this.organizationName });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.store.dispatch(new CreateOrganization(result));
          this.router.navigate([APPROUTES.home]);
        }
      })
    );
  }

  public createInvite() {
    const dialogRef = this.dialog.open(InviteDialogComponent, {
      data: { callBackUrl: "", users: this.inviteEmails },
    });
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.inviteEmails.push(result);
          this.store.dispatch(new InviteToOrganization(this.inviteEmails));
        }
      })
    );
  }

  public mapStateToProps(): void {
    this.bindSelectorToProperty(this.$currentUser, "currentUser");
    this.bindSelectorToProperty(this.$users, "users");
  }
}
