/**
 * Angular 2 decorators and services
 */
import { Component, OnInit, ViewEncapsulation, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { AppSettings } from "./infrastructure/appsettings";
import { SidenavService } from "./infrastructure/sidenav/side-nav-service.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { APPROUTES } from "./app.routes.strings";
import { TranslateService } from "@ngx-translate/core"; // Translate Service required to translate
import { Router, NavigationCancel, NavigationEnd, NavigationError, NavigationStart } from "@angular/router";
import { AuthService } from "./infrastructure/auth/auth.service";

export const ROOT_SELECTOR = "app";

/**
 * App Component
 * Top Level Component
 */
@Component({
  selector: ROOT_SELECTOR,
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./app.component.html",
  providers: [AppSettings],
})
export class AppComponent implements OnInit {
  @ViewChild("sidenav", { static: true })
  public sidenav: MatSidenav;

  public loading = false;

  public constructor(
    private sidenavService: SidenavService,
    public appSettings: AppSettings,
    private translateService: TranslateService,
    public oidcSecurityService: OidcSecurityService,
    private router: Router,
    private authService: AuthService
  ) {
    this.subscribeToRouter();
  }

  public ngOnInit() {
    this.oidcSecurityService.checkAuth().subscribe((isAuthenticated) => {
      this.onAuthorizationResultComplete(isAuthenticated);
    });

    // Sets the default Language for ngx-translate
    this.translateService.setDefaultLang("en");
    this.sidenavService.setSidenav(this.sidenav);
  }

  /**
   * Subscribe to router events and handle them
   */
  private subscribeToRouter() {
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          // when navigation starts, display loading icon
          this.loading = true;

          const myEvent = event as NavigationStart;
          // Save the route to sessionstorage so we can retrieve what route users were on after
          // authentication
          if (
            ![`/${APPROUTES.unauthorized}`, `/${APPROUTES.forbidden}`, "/silent-renew.html"].includes(myEvent.url) &&
            !myEvent.url.includes("#id_token")
          ) {
            this.authService.setAuthRedirectUrl(myEvent.url);
          }
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        // don't do anything if a random event fires
        default: {
          break;
        }
      }
    });
  }

  /**
   * Runs whenever the oidcservice returns an Authorizationresult
   *
   * @param authorizationResult
   */
  private onAuthorizationResultComplete(isAuthorized: boolean) {
    if (isAuthorized) {
      this.router.navigateByUrl(this.authService.getAuthRedirectUrl());
    } else {
      this.router.navigate([[APPROUTES.unauthorized]]);
    }
  }
}
