import { StateContainerComponent } from "app/infrastructure/state/state-container-component/state-container.component";
import { OnInit, Component, OnDestroy } from "@angular/core";
import { Store } from "@ngxs/store";
import { Router } from "@angular/router";
import { RegisterUser } from "./state/actions/RegisterUser";
import { UserRegistration } from "./models/userRegistration";
import { APPROUTES } from "app/app.routes.strings";
import { Subscription } from "rxjs";
import { AuthService } from "../../infrastructure/auth/auth.service";
import UserDataModel from "./../../infrastructure/auth/models/UserDataModel";
import UserStateModel from "./state/models/UserStateModel";

@Component({
  templateUrl: "UserRegistration.component.html",
  styleUrls: ["UserRegistration.component.scss"],
})
export class UserRegistrationComponent extends StateContainerComponent implements OnInit, OnDestroy {
  public registerUser: UserRegistration;
  public registerFormUser: UserRegistration = {
    username: "",
    email: "",
  };

  public registerSubscription: Subscription;

  public constructor(private authService: AuthService, private store: Store, private router: Router) {
    super();
    this.prefillRegistrationForm();
  }

  private prefillRegistrationForm() {
    const userData: UserDataModel = this.authService.getRegistrationFormUserData();
    if (userData !== undefined) {
      this.registerFormUser.username = userData.userName;
      this.registerFormUser.email = userData.email;
    }
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    if (this.registerSubscription) {
      this.registerSubscription.unsubscribe();
    }
  }

  public register() {
    this.store.dispatch(new RegisterUser(this.registerFormUser));

    // after registering we want to know when it was successful, we watch state and determine whether the current user has changed
    this.registerSubscription = this.store.subscribe((val: UserStateModel): void => {
      if (val.currentUser) {
        this.router.navigate([APPROUTES.home]);
      }
    });
  }
}
