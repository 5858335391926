import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { UserSettings } from "app/infrastructure/usersettings/usersettings.service";

@Pipe({
  name: "infDate",
})
export class InfDatePipe extends DatePipe implements PipeTransform {
  /**
   * Format of the returned date, this is gotten from the usersettings
   */
  public format = UserSettings.settings.DateFormatterValues.date;

  /**
   * Text to display if the formatting goes wrong
   */
  public NA = "n.a.";

  /**
   * Given either a date or a string returns a date object
   *
   * @param input a date or string
   */
  protected getDateObject(input: Date | string): Date {
    return input instanceof Date ? input : new Date(input);
  }

  /**
   * Check whether the date is valid
   *
   * @param date
   */
  protected isDateValid(date: Date) {
    if (
      !date ||
      Object.prototype.toString.call(date) !== "[object Date]" ||
      !(date instanceof Date) ||
      isNaN(date.getTime()) ||
      date.toString().toLowerCase() === "invalid date"
    ) {
      return false;
    }

    return true;
  }

  /**
   * Date transform function.
   * makes sure that the input becomes a date object
   * checks whether date is valid
   * returns "NA" if it isn't valid or a formatted string if it is valid
   *
   * @param date
   * @param customFormat AVOID USING THIS. Only use it if the library you use requires a string formatted date in a specific way....
   */
  public transform(date: Date | string, customFormat?: string): string {
    // new Date of null results in 1970-01-01T00:00:00.000Z, which is valid but not desired
    if (date === null) {
      return this.NA;
    }

    const dateObject = this.getDateObject(date);
    if (!this.isDateValid(dateObject)) {
      return this.NA;
    }

    return super.transform(dateObject, customFormat ? customFormat : this.format);
  }
}
