export const APPROUTES = {
  home: "",
  toasters: "toasters",
  inputs: "inputs",
  about: "about",
  aboutus: "about/us",
  aboutversion: "about/version",
  contextmenus: "contextmenus",
  perfectscrollbar: "perfectscrollbar",
  forbidden: "forbidden",
  unauthorized: "unauthorized",
  users: {
    details: "users/details",
    register: "users/register",
  },
  organization: {
    base: "organization",
  },
  shipments: "shipments",
  catchAll: "**",
};
