import { State, Selector, Action, StateContext, Store } from "@ngxs/store";
import { HttpClient, HttpParams, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from "app/infrastructure/toastr/toastr.service";
import { tap } from "rxjs/operators";
import { AppSettings } from "app/infrastructure/appsettings";
import OrganizationStateModel from "./models/OrganizationStateModel";
import { InviteToOrganization } from "./actions/InviteToOrganization";
import { JoinOrganization } from "./actions/JoinOrganization";
import { CreateOrganization } from "./actions/CreateOrganization";
import { GetCurrentUser } from "app/features/users/state/actions/GetCurrentUser";
import { GetUsersForYourOrganization } from "./actions/GetUsersForYourOrganization";
import { User } from "app/features/users/models/user";
import { UtilityService } from "app/shared/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";

@State<OrganizationStateModel>({
  name: "organization",
  defaults: {},
})
@Injectable()
export class OrganizationState {
  public constructor(
    private toastrService: ToastrService,
    private http: HttpClient,
    private store: Store,
    private translateService: TranslateService
  ) {}

  @Selector()
  public static Users(state: OrganizationStateModel) {
    return state.users;
  }

  @Action(InviteToOrganization)
  public inviteToOrganization(ctx: StateContext<OrganizationStateModel>, action: InviteToOrganization) {
    // TODO There is a ticket for using the fancy callbackUrl, also fix this then.
    const inviteModel = { callBackUrl: "", emailAddresses: action.emails };
    const params = new HttpParams();
    return this.http
      .post(
        `${AppSettings.settings.BackendBaseUrl}/${AppSettings.settings.BackendPaths.inviteToOrganization}`,
        inviteModel,
        {
          params,
          observe: "response",
          responseType: "text",
        }
      )
      .pipe(
        tap(() => {
          this.toastrService.success(this.translateService.instant("Invite successfully sent."));
        }, this.handleError)
      );
  }

  @Action(JoinOrganization)
  public joinOrganization(ctx: StateContext<OrganizationStateModel>, action: JoinOrganization) {
    const params = new HttpParams();
    const organizationId = action.organizationId;
    const inviteKey = action.inviteKey;
    return this.http
      .put(
        `${AppSettings.settings.BackendBaseUrl}/${AppSettings.settings.BackendPaths.organizations}/${organizationId}/join/${inviteKey}`,
        {
          params,
          observe: "response",
          responseType: "text",
        }
      )
      .pipe(
        tap(() => {
          this.toastrService.success(this.translateService.instant("You have successfully joined the organization."));
          this.store.dispatch(new GetCurrentUser());
        }, this.handleError)
      );
  }

  @Action(CreateOrganization)
  public createOrganization(ctx: StateContext<OrganizationStateModel>, action: CreateOrganization) {
    const params = new HttpParams();
    return this.http
      .post(
        `${AppSettings.settings.BackendBaseUrl}/${AppSettings.settings.BackendPaths.createOrganization}`,
        { name: action.organizationName },
        {
          params,
          observe: "response",
          responseType: "text",
        }
      )
      .pipe(
        tap(() => {
          this.toastrService.success("Successfully created organization.");
          this.store.dispatch(new GetCurrentUser());
        }, this.handleError)
      );
  }

  @Action(GetUsersForYourOrganization)
  public getUsersForYourOrganization(ctx: StateContext<OrganizationStateModel>, action: GetUsersForYourOrganization) {
    const params = new HttpParams();
    if (UtilityService.objectIdNotNullOrDefault(action.organizationId)) {
      return this.http
        .get(
          `${AppSettings.settings.BackendBaseUrl}/${AppSettings.settings.BackendPaths.organizations}/${action.organizationId}/users`,
          {
            params,
            observe: "response",
          }
        )
        .pipe(
          tap((response: HttpResponse<User[]>) => {
            ctx.patchState({ users: response.body });
          }, this.handleError)
        );
    }
    return null;
  }

  /**
   * give a toast on error
   * arrow function to preserve binding to state class
   *
   * @param error
   */
  public handleError = (error: HttpErrorResponse) => {
    this.toastrService.error(error.message);
  };
}
