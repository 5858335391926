import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../auth.service";
import { ToastrService } from "../../../toastr/toastr.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationState } from "../../state/auth.state";
import { Observable } from "rxjs";
import { Select } from "@ngxs/store";
import { StateContainerComponent } from "app/infrastructure/state/state-container-component/state-container.component";
import { Router } from "@angular/router";
import { APPROUTES } from "../../../../app.routes.strings";
import { UserState } from "app/features/users/state/user.state";
import { User } from "app/features/users/models/user";

@Component({
  selector: "app-identity-menu",
  templateUrl: "./identity-menu.component.html",
})
export class IdentityMenuComponent extends StateContainerComponent implements OnInit {
  @Select(AuthenticationState.isAuthenticated)
  public stateAuthentication: Observable<boolean>;

  private isAuthenticated = false;
  @Select(UserState.CurrentUser)
  public currentUserState: Observable<User>;
  private currentUser: User;

  public constructor(
    private auth: AuthService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private router: Router
  ) {
    super();
  }

  /**
   * login function
   */
  public login() {
    this.auth.login();
  }

  /**
   * logout function
   */
  public logout() {
    this.auth.logout();
  }

  public viewProfile() {
    this.router.navigate([APPROUTES.users.details]);
  }

  public register() {
    this.router.navigate([APPROUTES.users.register]);
  }

  public mapStateToProps(): void {
    this.bindSelectorToProperty(this.stateAuthentication, "isAuthenticated");
    this.bindSelectorToProperty(this.currentUserState, "currentUser");
  }
}
