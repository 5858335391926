// TODO: move to package

import { HttpClient, HttpResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ResponseWithCount } from "./models/responseWithCount";
import { map } from "rxjs/operators";
import { ApiConstants } from "app/shared/models/constants/ApiConstants";
import { Filter } from "app/shared/models/filter";
import { SieveFilterService } from "@inforit/filtering";

@Injectable()
export abstract class CrudService<T> {
  public filtersService: SieveFilterService;
  public constructor(public http: HttpClient) {
    this.filtersService = new SieveFilterService();
  }

  abstract get url(): string;

  /**
   * Get the result of a http get call in the form: {count: 0, items: []}
   *
   * @param params http params to be passed
   */
  public get(filter: Filter, params?: HttpParams): Observable<ResponseWithCount<T>> {
    return this.getHttpResponse(filter, params).pipe(
      map((response: HttpResponse<T[]>) => {
        const count = Number(response.headers.get(ApiConstants.COUNT_HEADER));
        return { count, items: response.body };
      })
    );
  }

  /**
   * Get the result (response) of a http get call from the back-end.
   *
   * @param filter
   * @param params
   */
  public getHttpResponse(filter: Filter, params?: HttpParams): Observable<HttpResponse<T[]>> {
    return this.http.get<T[]>(`${this.url}?${this.filtersService.getFilterValue(filter)}`, {
      observe: "response",
      params,
    });
  }

  /**
   * Executes a POST http call given some data
   *
   * @param data
   * @param params
   */
  public post(data: T, params?: HttpParams): Observable<HttpResponse<T>> {
    return this.http.post<T>(this.url, data, { observe: "response", params });
  }

  /**
   * Executes a PUT http call given some data
   *
   * @param data
   * @param params
   */
  public put(data: any, params?: HttpParams): Observable<HttpResponse<T>> {
    return this.http.put<T>(this.url, data, { observe: "response", params });
  }

  /**
   * Executes a DELETE http call given an identifier
   *
   * @param id
   * @param params
   */
  public delete(id: string, params?: HttpParams): Observable<HttpResponse<T>> {
    return this.http.delete<T>(`${this.url}/${id}`, { observe: "response", params });
  }
}
