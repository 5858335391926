import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { JoinModel } from "./state/models/JoinModel";

@Component({
  templateUrl: "JoinDialog.component.html",
})
export class JoinDialogComponent {
  public constructor(
    public dialogRef: MatDialogRef<JoinDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: JoinModel
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }

  /**
   * Removes all whitespace from this.data before closing with it
   */
  public submit(): void {
    Object.keys(this.data).forEach((key) => {
      this.data[key] = (this.data[key] as string).replace(/ /g, "");
    });

    this.dialogRef.close(this.data);
  }
}
