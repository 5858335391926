import { Routes } from "@angular/router";
import { HomeComponent } from "./features/home";
import { UnauthorizedComponent } from "./infrastructure/oidc/unauthorized/unauthorized.component";

import { APPROUTES } from "./app.routes.strings";
import { ABOUT_ROUTES } from "./features/about/about.routes";
import { CONTEXT_MENU_ROUTES } from "./features/context-menu/contextmenu.routes";
import { TOASTERS_ROUTES } from "./features/toasters/toasters.routes";
import { INPUTS_ROUTES } from "./features/inputs/inputs.routes";
import { PERFECT_SCROLLBAR_ROUTES } from "./features/perfect-scrollbar/perfectscrollbar.routes";
import { USER_ROUTES } from "./features/users/users.routes";
import { ORGANIZATION_ROUTES } from "./features/organizations/organizations.routes";
import { SHIPMENT_ROUTES } from "./features/shipments/shipments.routes";

export const ROUTES: Routes = [
  { path: APPROUTES.home, component: HomeComponent },

  // feature routes
  ...ABOUT_ROUTES,
  ...USER_ROUTES,
  ...SHIPMENT_ROUTES,
  ...ORGANIZATION_ROUTES,
  ...CONTEXT_MENU_ROUTES,
  ...TOASTERS_ROUTES,
  ...INPUTS_ROUTES,
  ...PERFECT_SCROLLBAR_ROUTES,

  // unauthorized
  { path: APPROUTES.unauthorized, component: UnauthorizedComponent },
  { path: APPROUTES.forbidden, component: UnauthorizedComponent },
  // else... 404 -> redirect home
  { path: APPROUTES.catchAll, redirectTo: "/" },
];
