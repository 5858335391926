import { Routes } from "@angular/router";
import { UserDetailsComponent } from "./UserDetails.component";
import { APPROUTES } from "../../app.routes.strings";
import { AuthGuardService } from "app/infrastructure/oidc/auth-guard.service";
import { UserRegistrationComponent } from "./UserRegistration.component";

export const USER_ROUTES: Routes = [
  { path: APPROUTES.users.details, component: UserDetailsComponent, canActivate: [AuthGuardService] },
  { path: APPROUTES.users.register, component: UserRegistrationComponent, canActivate: [AuthGuardService] },
];
