import { Injectable, OnDestroy } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { AuthenticationState } from "./state/auth.state";
import { Observable, Subscription } from "rxjs";
import { ChangeAuthentication } from "./state/actions/Authenticate";
import { StateContainerService } from "../state/state-container-service/state-container.service";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { GetCurrentUser } from "app/features/users/state/actions/GetCurrentUser";
import { TranslateService } from "@ngx-translate/core";
import UserDataModel from "./models/UserDataModel";

@Injectable({
  providedIn: "root",
})
export class AuthService extends StateContainerService implements OnDestroy {
  @Select(AuthenticationState.isAuthenticated)
  public stateAuthentication: Observable<boolean>;

  private authenticated = false;

  private isAuthorizedObservable: Observable<boolean>;
  private isAuthorizedSubscription: Subscription;

  private userData: UserDataModel;

  public constructor(
    public store: Store,
    private oidcSecurityService: OidcSecurityService,
    public translateService: TranslateService
  ) {
    super();
    this.oidcSecurityService.stsCallback$.subscribe(this.doCallbackLogicIfRequired);

    this.oidcSecurityService.isAuthenticated$.subscribe((isAuthenticated: boolean) => {
      this.setAuthentication(isAuthenticated);
    });
  }

  public ngOnDestroy() {
    this.isAuthorizedSubscription.unsubscribe();
  }

  private doCallbackLogicIfRequired = () => {
    if (window.location.hash) {
      this.oidcSecurityService.checkAuth().subscribe((isAuthenticated) => {
        this.setAuthentication(isAuthenticated);
      });
    }
  };
  /**
   * return whether the user is authenticated
   */
  public isAuthenticated() {
    return this.authenticated;
  }

  /**
   * check whether the user has privileges to perform an action
   */
  public isAuthorized() {
    return this.authenticated;
  }

  /**
   * Function to log in
   */
  public login() {
    this.oidcSecurityService.authorize();
  }

  /**
   * Function to log out
   */
  public logout() {
    this.oidcSecurityService.logoff();
    this.setAuthRedirectUrl("/");
  }

  /**
   * Function to get some user data from
   * the signed in user, so when they want
   * register the form has pre-filled values
   */
  public getRegistrationFormUserData() {
    this.oidcSecurityService.userData$.subscribe((userData: UserDataModel) => {
      if (userData !== undefined) {
        this.userData = {
          email: userData.email,
          userName: userData.preferredUserName,
        };
      }
    });

    return this.userData;
  }

  /**
   * Change authentication in state
   *
   * @param isAuthorized
   */
  private setAuthentication(isAuthorized: boolean) {
    this.store.dispatch(new ChangeAuthentication(isAuthorized));

    /**
     * We can't dispatch the event immediately because the oidcService needs some time to set the token
     * This will check the token, if empty wait 200ms and try again.
     * Does this 10 times before failing.
     *
     * @param currentCount
     */
    const getCurrentUserIfTokenIsPresent = (currentCount) => {
      if (currentCount <= 10) {
        currentCount++;
        if (this.oidcSecurityService.getToken() !== "") {
          this.store.dispatch(new GetCurrentUser());
        } else {
          setTimeout(() => {
            getCurrentUserIfTokenIsPresent(currentCount);
          }, 200);
        }
      } else {
        throw new Error(this.translateService.instant("auth.unauthorizedError"));
      }
    };

    if (isAuthorized) {
      getCurrentUserIfTokenIsPresent(1);
    }
  }

  /**
   * Get the redirect url from a dataStore
   */
  public getAuthRedirectUrl(): string {
    const data = sessionStorage.getItem("redirect");
    if (data != null) {
      return data;
    } else {
      return "";
    }
  }

  /**
   * set the redirect url in a datastore
   *
   * @param value value to store
   */
  public setAuthRedirectUrl(value: any): void {
    sessionStorage.setItem("redirect", value);
  }

  /**
   * Map state to properties
   */
  public mapStateToProps(): void {
    this.bindSelectorToProperty(this.stateAuthentication, "authenticated");
  }
}
