import { Pipe, PipeTransform } from "@angular/core";
import { UserSettings } from "app/infrastructure/usersettings/usersettings.service";
import { InfDatePipe } from "./date.pipe";

@Pipe({
  name: "infDateTime",
})
export class InfDateTimePipe extends InfDatePipe implements PipeTransform {
  /**
   * Format of the returned date, this should be gotten from appsettings/usersettings
   */
  public format = UserSettings.settings.DateFormatterValues.dateTime;
}
