import { StateContainerComponent } from "app/infrastructure/state/state-container-component/state-container.component";
import { OnInit, Component, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { Observable, fromEvent } from "rxjs";
import { ShipmentsState } from "./state/shipments.state";
import { Shipment } from "./models/shipment";
import { GetShipments } from "./state/actions/GetShipments";
import { Sort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { SelectionModel } from "@angular/cdk/collections";
import { Action } from "./models/action";
import { Filter } from "app/shared/models/filter";
import { UpdateShipmentsFilter } from "./state/actions/UpdateShipmentsFilter";
import { FilterItem } from "app/shared/models/filters/filter.item";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { MileStoneDialogComponent } from "./dialogs/milestone.dialog";
import { FilterDefinition, Operator, FilterType, UtilityService, SieveFilterService } from "@inforit/filtering";

@Component({
  templateUrl: "shipments.component.html",
})
export class ShipmentsComponent extends StateContainerComponent implements OnInit, AfterViewInit {
  @Select(ShipmentsState.Shipments)
  public stateShipments$: Observable<Shipment[]>;
  public shipments: Shipment[];
  public selection = new SelectionModel(true, []);

  @Select(ShipmentsState.filter)
  public stateFilter$: Observable<Filter>;
  public filter: Filter = new Filter();

  @Select(ShipmentsState.loading)
  public loading$: Observable<boolean>;

  @Select(ShipmentsState.count)
  public stateCount$: Observable<number>;
  public count: number;

  @ViewChild("searchBox", { static: false }) public searchBox: ElementRef<HTMLInputElement>;
  public searchFields = ["reference", "customerReference", "equipment.number"];
  public searchValue = "";

  public displayedColumns: string[] = [
    "reference",
    "status",
    "shipperCode",
    "loadingLocation",
    "deliverLocation",
    "plannedDeliverDateTime",
    "eta",
    "ontime",
    "milestones",
  ];

  /**
   * Filter field definitions
   */
  public filterDefinitions: FilterDefinition[] = [
    {
      type: FilterType.text,
      name: "shipper.code",
      displayName: this.translate.instant("shipments.shipperCode") as string,
      disabled: false,
      placeholder: "",
      operator: Operator.CASE_INSENSITIVE_STRING_CONTAINS,
    },
    {
      type: FilterType.select,
      name: "status",
      displayName: this.translate.instant("shipments.status") as string,
      disabled: false,
      placeholder: "Select a status",
      options: [
        { value: "new", viewValue: "new" },
        { value: "accepted", viewValue: "accepted" },
        { value: "inTransit", viewValue: "inTransit" },
        { value: "finished", viewValue: "finished" },
        { value: null, viewValue: "All" },
      ],
      operator: Operator.EQUALS,
    },
    {
      type: FilterType.switch,
      name: "hideShipments",
      displayName: this.translate.instant("shipments.hideShipments") as string,
      disabled: false,
    },
  ];

  public constructor(
    private store: Store,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog,
    public filterService: SieveFilterService
  ) {
    super();
  }

  public ngOnInit() {
    super.ngOnInit();
    this.refresh();
  }

  public ngAfterViewInit(): void {
    // subscribe on keyup and detect changes every 500ms (debounce). If so,
    // update the filter.
    fromEvent(this.searchBox.nativeElement, "keyup")
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          const value: string = this.searchBox.nativeElement.value;
          const filters: FilterItem[] = [
            {
              fields: this.searchFields,
              values: value.split("|"),
              operator: "@=*",
            },
          ];

          const searchFilter = this.filterService.UpdateFilterWithPartial(filters, this.filter);
          this.store.dispatch(new UpdateShipmentsFilter({ filters: searchFilter.filters }, true));
        })
      )
      .subscribe();
  }

  /**
   * Opens a milestone popup focused on a shipment
   *
   * @param shipment
   */
  public openMileStones(shipment: Shipment) {
    this.dialog.open(MileStoneDialogComponent, {
      data: [...shipment.milestones],
      minWidth: "70vw",
    });
  }

  public refresh() {
    this.store.dispatch(new GetShipments());
  }

  /**
   * Returns the loading location of the shipment.
   *
   * @param shipment
   */
  public shipmentLoadingLocation(shipment: Shipment) {
    if (!shipment.loadingLocation) {
      return this.translate.instant("n.a.") as string;
    }

    return [shipment.loadingLocation.name, shipment.loadingLocation.place, shipment.loadingLocation.country.code]
      .filter((val) => val)
      .join(", ");
  }

  /**
   * Returns the deliver location of the shipment.
   *
   * @param shipment
   */
  public shipmentDeliverLocation(shipment: Shipment) {
    if (!shipment.loadingLocation) {
      return this.translate.instant("n.a.") as string;
    }

    return [shipment.deliveryLocation.name, shipment.deliveryLocation.place, shipment.deliveryLocation.country.code]
      .filter((val) => val)
      .join(", ");
  }

  private GetLocationDescription(action: Action) {
    if (!action) {
      return this.translate.instant("n.a.") as string;
    }
    return [action.address.name, action.address.address, action.address.country.code].join(", ");
  }

  /**
   * Return the last milestone (sorted on sequence) which has the status of "reached"
   *
   * @param shipment
   */
  public getLastReachedMileStone(shipment: Shipment) {
    let returnMileStone = { name: "" };

    if (shipment.milestones) {
      const milestones = [...shipment.milestones].sort((a, b) => a.sequence - b.sequence);
      milestones.reverse();
      const latestReached = milestones.find((m) => m.reached);
      if (latestReached) {
        returnMileStone = latestReached;
      }
    }

    return returnMileStone;
  }

  /**
   * Handles the paginator event by dispatching a new filter
   *
   * @param event
   */
  public handlePageEvent(event: PageEvent) {
    this.store.dispatch(new UpdateShipmentsFilter({ page: event.pageIndex + 1, pageSize: event.pageSize }));
  }

  /**
   * Method to handle material sort events
   *
   * @param sort
   */
  public sortData(sort: Sort) {
    this.store.dispatch(
      new UpdateShipmentsFilter({
        sorts: this.filterService.materialSortToSortItems(sort),
      })
    );
  }

  /**
   * Update the search field from the existing filter
   *
   * @param filter
   */
  public updateSearchField(filter: Filter): void {
    if (filter && filter.filters) {
      const searchFilter: FilterItem = filter.filters.find((f) =>
        UtilityService.arraysEqual(f.fields, this.searchFields)
      );
      if (searchFilter) {
        this.searchValue = searchFilter.values[0];
      }
    }
  }

  public mapStateToProps(): void {
    this.bindSelectorToProperty(this.stateShipments$, "shipments");
    this.bindSelectorToProperty(this.stateFilter$, "filter", (val: Filter) => {
      // when we receive a filter update from the state...
      // update the searchfield
      this.updateSearchField({ ...val });

      return val;
    });
    this.bindSelectorToProperty(this.stateCount$, "count");
  }

  /**
   * This function is called by the filter component and contains a key/value
   * pair of filterName: enteredValue
   * We can use it to update the filter.
   *
   * @param filters filters to add/update
   */
  public handleFilterComponentUpdate(filters: FilterItem[]) {
    this.store.dispatch(new UpdateShipmentsFilter({ ...this.filter, filters }, true));
  }

  /**
   * Handles removal of a filter
   *
   * @param event
   */
  public handleFilterComponentRemoved(filters: FilterItem[]) {
    const newFilterItems = this.filter.filters.filter((filterItem: FilterItem) => !filters.includes(filterItem));

    this.store.dispatch(new UpdateShipmentsFilter({ ...this.filter, filters: newFilterItems }, true));
  }
}
