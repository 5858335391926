import { Injectable } from "@angular/core";
import { DEFAULT_OBJECT_ID } from "app/infrastructure/mongo/defaultObjectId";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  public constructor() {}

  /**
   * Check if the given string is a valid objectId (response)
   */
  public static objectIdNotNullOrDefault(objectId: string) {
    return objectId && objectId !== DEFAULT_OBJECT_ID;
  }
}
