import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { OidcSecurityService, TokenValidationService } from "angular-auth-oidc-client";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ToastrService } from "../toastr/toastr.service";
import { Router } from "@angular/router";
import { APPROUTES } from "app/app.routes.strings";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
  private oidcSecurityService: OidcSecurityService;
  private tokenValidationService: TokenValidationService;

  public constructor(
    private readonly injector: Injector,
    private readonly toastrService: ToastrService,
    private router: Router
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestToForward = req;

    if (this.oidcSecurityService === undefined) {
      this.oidcSecurityService = this.injector.get(OidcSecurityService);
    }

    if (this.oidcSecurityService !== undefined) {
      const token = this.oidcSecurityService.getToken();

      if (token !== "") {
        const tokenValue = `Bearer ${token}`;

        requestToForward = req.clone({
          setHeaders: { Authorization: tokenValue },
        });
      }
    } else {
      console.debug("OidcSecurityService undefined: No auth header!");
    }

    return next.handle(requestToForward).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
            if (this.tokenValidationService === undefined) {
              this.tokenValidationService = this.injector.get(TokenValidationService);
            }

            const token = this.oidcSecurityService.getToken();

            if (token === "" || this.tokenValidationService.hasIdTokenExpired(token)) {
              this.oidcSecurityService.authorize();
            }
            break;
          case 403:
            // forbidden, navigate to the forbidden page
            this.router.navigate([APPROUTES.forbidden]);
            break;
        }

        if (error.url.includes("sts")) {
          this.toastrService.error(error.message);
        }

        next.handle(requestToForward);

        return throwError(error);
      })
    );
  }
}
