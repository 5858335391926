import { State, Selector, Action, StateContext, Store } from "@ngxs/store";
import { User } from "../models/user";
import UserStateModel from "./models/UserStateModel";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { ToastrService } from "app/infrastructure/toastr/toastr.service";
import { tap } from "rxjs/operators";
import { GetUsers } from "./actions/GetUsers";
import { GetCurrentUser } from "./actions/GetCurrentUser";
import { Organization } from "../models/organization";
import { UpdateUser } from "./actions/UpdateUser";
import { DeleteUser } from "./actions/DeleteUser";
import { RegisterUser } from "./actions/RegisterUser";
import { AppSettings } from "app/infrastructure/appsettings";
import { SetCurrentUser } from "./actions/SetCurrentUser";
import { SetLoading } from "./actions/SetLoading";
import { UtilityService } from "app/shared/services/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { APPROUTES } from "app/app.routes.strings";
import { Injectable } from "@angular/core";
import APIErrorResponse from "./../../../infrastructure/apiErrorResponse";

@State<UserStateModel>({
  name: "user",
  defaults: {
    loading: false,
  },
})
@Injectable()
export class UserState {
  public constructor(
    public toastrService: ToastrService,
    private http: HttpClient,
    private store: Store,
    private translate: TranslateService,
    private route: Router
  ) {}

  @Selector()
  public static Users(state: UserStateModel) {
    return state.users;
  }

  @Selector()
  public static CurrentUser(state: UserStateModel) {
    return state.currentUser;
  }

  @Selector()
  public static isLoading(state: UserStateModel) {
    return state.loading;
  }

  /** *
   * Get all users in the application and put it in the users state.
   */
  @Action(GetUsers)
  public getUsers(ctx: StateContext<UserStateModel>) {
    ctx.patchState({ loading: true });
    const params = new HttpParams();
    return this.http
      .get(`${AppSettings.settings.BackendBaseUrl}/${AppSettings.settings.BackendPaths.users}`, {
        params,
        observe: "response",
      })
      .pipe(
        tap((response: HttpResponse<User[]>) => {
          ctx.patchState({
            users: response.body,
            loading: false,
          });
        }, this.handleError)
      );
  }

  /**
   * Gets the current user based on the logged in STS account.
   * If the user has an organization, also get that.
   * Save these values in CurrentUser state to be used throughout the whole application.
   */
  @Action(GetCurrentUser)
  public getCurrentUser(ctx: StateContext<UserStateModel>) {
    ctx.patchState({ loading: true });
    const params = new HttpParams();
    // Get the current user
    this.http
      .get(`${AppSettings.settings.BackendBaseUrl}/${AppSettings.settings.BackendPaths.currentUser}`, {
        params,
        observe: "response",
      })
      .subscribe(
        (userSuccess: HttpResponse<User>) => {
          const userObject = { ...userSuccess.body };
          // if the user is part of an organization, get organization.
          if (UtilityService.objectIdNotNullOrDefault(userSuccess.body.organizationId)) {
            this.http
              .get(
                `${AppSettings.settings.BackendBaseUrl}/${AppSettings.settings.BackendPaths.organizations}/` +
                  userSuccess.body.organizationId,
                {
                  params,
                  observe: "response",
                }
              )
              .subscribe((organizationSuccess: HttpResponse<Organization>) => {
                userObject.organization = organizationSuccess.body;

                ctx.dispatch(new SetCurrentUser(userObject));
              }, this.handleError);
          } else {
            ctx.dispatch(new SetCurrentUser(userObject));
          }
        },
        (error: APIErrorResponse) => {
          // ignore the forbidden errors here, we will often ask for the current user, the application user doesn't need to see this unauthorized error.
          // When we have no user the page will detect this from the state an handle accordingly.
          if (error.status !== 403 && error.status !== 401) {
            this.handleError(error);
          }
        }
      );
  }

  /**
   * Set the current user.
   * Needed because of ctx lifetime issues.
   */
  @Action(SetCurrentUser)
  public SetCurrentUser(ctx: StateContext<UserStateModel>, action: SetCurrentUser) {
    ctx.patchState({ currentUser: action.currentUser, loading: false });
  }

  /**
   * Update the user, also update the state.
   */
  @Action(UpdateUser)
  public UpdateUser(ctx: StateContext<UserStateModel>, action: UpdateUser) {
    const params = new HttpParams();
    return this.http
      .put(
        `${AppSettings.settings.BackendBaseUrl}/${AppSettings.settings.BackendPaths.users}/` + action.user.id,
        action.user,
        {
          params,
          observe: "response",
          responseType: "text",
        }
      )
      .pipe(
        tap(() => {
          this.store.dispatch(new GetCurrentUser());
          this.toastrService.success(this.translate.instant("User successfully updated"));
        }, this.handleError)
      );
  }

  /**
   * Delete a user
   */
  @Action(DeleteUser)
  public DeleteUser(ctx: StateContext<UserStateModel>, action: DeleteUser) {
    const params = new HttpParams();
    return this.http
      .delete(`${AppSettings.settings.BackendBaseUrl}/${AppSettings.settings.BackendPaths.users}/` + action.id, {
        params,
        observe: "response",
      })
      .pipe(
        tap(() => {
          ctx.patchState({
            currentUser: null,
          });
          this.toastrService.success("Account deleted successfully.");
          this.route.navigate([APPROUTES.home]);
        }, this.handleError)
      );
  }

  /**
   * Register a user to this application, based on STS account.
   */
  @Action(RegisterUser)
  public RegisterUser(ctx: StateContext<UserStateModel>, action: RegisterUser) {
    const params = new HttpParams();
    return this.http
      .post(`${AppSettings.settings.BackendBaseUrl}/${AppSettings.settings.BackendPaths.registerUser}`, action.user, {
        params,
        observe: "response",
        responseType: "text",
      })
      .pipe(
        tap(() => {
          this.toastrService.success("Successfully created account.");
          this.store.dispatch(new GetCurrentUser());
        }, this.handleError)
      );
  }

  @Action(SetLoading)
  public SetLoading(ctx: StateContext<UserStateModel>, action: SetLoading) {
    ctx.patchState({ loading: action.isLoading });
  }

  /**
   * give a toast on error
   * arrow function to preserve binding to state class
   *
   * @param response
   */
  public handleError = (response: APIErrorResponse) => {
    this.toastrService.error(response.error.detail);
    this.loadingDone();
  };

  public loadingDone = () => {
    this.store.dispatch(new SetLoading(false));
  };
}
