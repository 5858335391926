import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngxs/store";
import { DeleteUser } from "./state/actions/DeleteUser";
import { User } from "app/features/users/models/user";

@Component({
  templateUrl: "DeleteConfirmationDialog.component.html",
})
export class DeleteConfirmationDialogComponent {
  public constructor(
    public dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,
    private store: Store,
    @Inject(MAT_DIALOG_DATA)
    public data: User
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onYesClick(): void {
    this.store.dispatch(new DeleteUser(this.data.id));
    this.dialogRef.close();
  }
}
