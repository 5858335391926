// TODO: move to package

import { FilterItem } from "./filters/filter.item";
import { SortItem } from "./filters/sort.item";

/**
 * A standard filter object for most pages
 */
export class Filter {
  public page?: number = 1;
  public pageSize?: number = 20;
  public filters?: FilterItem[] = [];
  public sorts?: SortItem[] = [];
}
