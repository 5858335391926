import { Injectable } from "@angular/core";
import { Shipment } from "./models/shipment";
import { map } from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AppSettings } from "app/infrastructure/appsettings";
import { CrudService } from "app/shared/services/crud/crud.service";
import { ResponseWithCount } from "app/shared/services/crud/models/responseWithCount";
import { Observable } from "rxjs";
import { Filter } from "app/shared/models/filter";
import { Milestone } from "./models/milestone";

import { DateTime } from "luxon";

@Injectable({
  providedIn: "root",
})
export class ShipmentService extends CrudService<Shipment> {
  public get url(): string {
    return `${AppSettings.settings.BackendBaseUrl}/${AppSettings.settings.BackendPaths.shipments}`;
  }

  public constructor(public http: HttpClient) {
    super(http);
  }

  /**
   * get shipments and apply transformations
   *
   * @param params
   */
  public get(filter: Filter, params?: HttpParams): Observable<ResponseWithCount<Shipment>> {
    return super.get(filter, params).pipe(
      map((response) => {
        const { count, items } = response;
        items.forEach((shipment) => {
          shipment.onTime = this.isShipmentOnTime(shipment);

          if (shipment.milestones) {
            this.updateSequenceToReadableOrder(shipment.milestones);
            this.updateActualWithOffset(shipment.milestones);
          }
        });
        return { count, items };
      })
    );
  }

  /**
   * Calculate whether a shipment is on time
   *
   * @param shipment
   */
  public isShipmentOnTime(shipment: Shipment): boolean {
    const eta = new Date(shipment.deliveryEta);
    const planned = new Date(shipment.deliveryDate);

    return eta <= planned;
  }

  /**
   * Update the sequence to a readable order
   *
   * @param Milestone[]
   */
  public updateSequenceToReadableOrder(milestones: Milestone[]) {
    milestones.sort((a, b) => a.sequence - b.sequence);

    let resetNr = 1;
    milestones.forEach((milestone) => {
      milestone.sequence = resetNr;

      resetNr++;
    });
  }

  /**
   * Update the actualDateTime of the milestone with the offset of
   * the plannedDateTime without changing the datetime
   *
   * @param Milestone[]
   */
  public updateActualWithOffset(milestones: Milestone[]) {
    milestones.forEach((milestone) => {
      // Set the offset of the ActualDateTime to the same of the PlannedDateTime
      if (milestone.plannedDateTime && milestone.actualDateTime !== null) {
        const utcOffset = DateTime.fromISO(milestone.plannedDateTime, { setZone: true });

        milestone.actualDateTime = DateTime.fromISO(milestone.actualDateTime)
          .toUTC(utcOffset.offset)
          .toString();
      } else if (milestone.actualDateTime !== null) {
        // create a datetime set to a specific timezone
        milestone.actualDateTime = DateTime.fromISO(milestone.actualDateTime)
          .setZone("Europe/Amsterdam")
          .toISO();
      }
    });
  }
}
