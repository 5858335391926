import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Milestone } from "../models/milestone";

@Component({
  selector: "milestone-dialog",
  templateUrl: "milestone.dialog.html",
})
export class MileStoneDialogComponent {
  public workData: Milestone[];
  public hideMilestones = true;

  public displayedColumns: string[] = ["sequence", "name", "reached", "plannedDateTime", "actualDateTime"];

  public constructor(@Inject(MAT_DIALOG_DATA) public data: Milestone[]) {
    this.workData = [...data].sort((a, b) => a.sequence - b.sequence);

    if (this.workData.length > 0) {
      this.hideMilestones = this.workData.filter((m) => m.plannedDateTime !== null).length === 0;
    }
  }
}
