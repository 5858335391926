import { State, Selector, Action, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { ShipmentStateModel } from "./models/ShipmentStateModel";
import { ToastrService } from "ngx-toastr";
import { GetShipments } from "./actions/GetShipments";
import { Shipment } from "../models/shipment";
import { UpdateShipmentsFilter } from "./actions/UpdateShipmentsFilter";
import { ShipmentService } from "../shipment.service";
import { ResponseWithCount } from "app/shared/services/crud/models/responseWithCount";
import { Injectable } from "@angular/core";
import APIErrorResponse from "./../../../infrastructure/apiErrorResponse";

@State<ShipmentStateModel>({
  name: "shipments",
  defaults: {
    filter: {
      pageSize: 10,
      page: 1,
      filters: [
        {
          fields: ["hideShipments"],
          values: ["true"],
          operator: "==",
        },
      ],
    },
  },
})
@Injectable()
export class ShipmentsState {
  public constructor(public toastrService: ToastrService, private shipmentService: ShipmentService) {}

  /** *
   * Get all shipments the user has viewing rights for
   */
  @Action(GetShipments)
  public getUsers(ctx: StateContext<ShipmentStateModel>) {
    ctx.patchState({ loading: true });

    return this.shipmentService.get(ctx.getState().filter).pipe(
      tap((response: ResponseWithCount<Shipment>) => {
        ctx.patchState({ shipments: response.items, count: response.count, loading: false });
      }, this.handleError)
    );
  }

  /**
   * select a single item
   *
   * @param ctx
   * @param action
   */
  @Action(UpdateShipmentsFilter)
  public UpdateFilter(ctx: StateContext<ShipmentStateModel>, action: UpdateShipmentsFilter) {
    ctx.patchState({
      loading: true,
    });

    if (action.resetPage) {
      action.filter.page = 1;
    }

    ctx.patchState({
      filter: { ...ctx.getState().filter, ...action.filter },
    });

    // refresh items after updating the filter
    ctx.dispatch(new GetShipments());
  }

  /**
   * give a toast on error
   * arrow function to preserve binding to state class
   *
   * @param response
   */
  public handleError = (response: APIErrorResponse) => {
    let message = response.message;
    if (response.error instanceof Error) {
      message = response.error.detail;
    }
    this.toastrService.error(message);
  };

  @Selector()
  public static Shipments(state: ShipmentStateModel) {
    return state.shipments;
  }

  @Selector()
  public static count(state: ShipmentStateModel) {
    return state.count;
  }

  @Selector()
  public static filter(state: ShipmentStateModel) {
    return state.filter;
  }
  @Selector()
  public static loading(state: ShipmentStateModel) {
    return state.loading;
  }
}
