import { Pipe, PipeTransform } from "@angular/core";
import { InfDateTimePipe } from "./dateTime.pipe";

import { DateTime } from "luxon";

@Pipe({
  name: "preserveOffsetDateTime",
})
export class PreserveOffsetDateTimePipe extends InfDateTimePipe implements PipeTransform {
  /**
   * Given either a date or a string returns a date object based on a Luxon DateTime
   * object to preserve the offset settings.
   *
   * @param input a date or string
   */
  protected getDateObject(input: Date | string): Date {
    const luxonDateTime = DateTime.fromISO(input.toString(), { setZone: true }).toISO({ includeOffset: false });
    return input instanceof Date ? input : new Date(luxonDateTime);
  }
}
